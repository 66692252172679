<template>
  <div>
    <Modal :options="{width: '40vw', type: 'info'}" @close="$emit('close')">
        <div class="title">Información de contacto de solicitud: <strong>{{ solicitud.solicitud }}</strong></div>
        <div class="body">
          <div class="row">
            <label class="col-sm-2">Correo:</label>
            <div class="col-sm-10">{{email}}</div>
          </div>
          <div class="row">
            <label class=" col-sm-2">Teléfono:</label>
            <div class="col-sm-10">{{telefono}}</div>
          </div>
          <div class="row">
            <label class="col-sm-2">Celular:</label>
            <div class="col-sm-10">{{celular}}</div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary" type="button" @click="$emit('close')">Cerrar</button>
            </div>
          </div>
        </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,dafault() {
          return {
            id: null
            ,solicitud: null
          }
        }
      }
    }
    ,data() {
      return {
        email: '',
        telefono: '',
        celular: ''
      }
    }
    ,mounted() {
      if (this.solicitud.datos_contacto && this.solicitud.datos_contacto.telefono){
        this.telefono = this.solicitud.datos_contacto.telefono;
      }
      if (this.solicitud.datos_contacto && this.solicitud.datos_contacto.celular){
        this.celular = this.solicitud.datos_contacto.celular;
      }
      this.email = (this.solicitud.datos_contacto && this.solicitud.datos_contacto.email) ?
          this.solicitud.datos_contacto.email : this.solicitud.email;
    }
  }
</script>

<style lang="scss" scoped>

</style>